<template>
  <div>
    <vs-popup class="sm:popup-w-80" title="Final Approve Purchase Request" :z-index="100000" :active="isActive" v-on:update:active="emitModalIsActive">
      <!--loading-->
      <vs-progress v-if="isLoadingGetInitData" indeterminate color="primary" :height="1"/>

      <div v-else>
        <div class="vx-row">
          <div class="vx-col w-full">
            <ValidationErrors :errors="errors"/>
          </div>
        </div>

        <div class="vx-row mb-3">
          <div class="vx-col sm:w-1/2 w-full">
            <label class="ml-1 text-xs">Tgl</label>
            <vs-input class="w-full" icon-pack="feather" icon="icon-calendar" v-model="data.tgl" disabled/>
          </div>
          <div class="vx-col sm:w-1/2 w-full">
            <label class="ml-1 text-xs">Status</label>
            <vs-input class="w-full" icon-pack="feather" icon="icon-calendar" value="APPROVE" disabled/>
          </div>
        </div>

        <div class="vx-row mb-1">
          <div class="vx-col sm:w-1/1 w-full">
            <label class="ml-1 text-xs">Keterangan</label>
            <vs-textarea class="w-full" v-model="data.keterangan"/>
          </div>
        </div>

        <div class="vx-row">
          <div class="w-full flex items-center justify-between mb-2">
            <label class="ml-6 text-xs">Pilih Rekanan Untuk Setiap Item</label>
            <div v-if="boxLeft.selected" @click="addRow" class="mr-6 flex items-center font-semibold">
              <feather-icon icon="PlusIcon" class="cursor-pointer text-success mr-1" svg-classes="h-4 w-4"/>
              <label class="text-xs text-success cursor-pointer select-none">Tambah</label>
            </div>
          </div>

          <div id="email-app" class="flex relative overflow-hidden w-full mx-5">
            <!-----------BOX LEFT------------->
            <div class="w-full sm:w-6/12">
              <div class="border border-solid d-theme-border-grey-light border-r-0 border-t border-b" style="height: 46vh">
                <component :is="scrollbarTag" class="email-content-scroll-area" style="height: calc(100%)" :settings="scrollbarSettings" ref="mailListPS">
                  <div>
                    <div v-for="item in initData.pengadaanD" :key="item.id" @click="boxLeft.selected = item" class="cursor-pointer rounded-none p-3 border border-solid d-theme-border-grey-light border-t-0 border-r-0 border-l-0 border-b" :class="{ 'bg-primary-transparent-25': boxLeft.selected === item }">
                      <div class="flex items-center justify-between mb-1">
                        <p class="item-name text-sm font-bold">{{ item.nama_item_pengadaan }} <span class="ml-1 opacity-50 text-xs">({{ item.kode_item_pengadaan }})</span></p>
                        <vx-tooltip :text="itemErrorsText(item) || 'Data sudah valid'">
                          <feather-icon class="px-3" icon="InfoIcon" :svg-classes="[item.isAnyError ? 'text-danger' : 'text-success', 'heads-5 w-5']"/>
                        </vx-tooltip>
                      </div>
                      <div class="flex items-center mb-1 opacity-75">
                        <p class="text-xs font-medium">{{ item.vendors.length }} Pilihan Rekanan</p>
                        <p class="text-xs mx-2">|</p>
                        <p class="text-xs font-medium">Quantity: {{ item.qty }}</p>
                      </div>
                      <div class="flex items-center cursor-pointer select-none opacity-75">
                        <p class="w-auto text-xs">{{ itemCheckedVendors(item).length }} Rekanan Dipilih</p>
                      </div>
                    </div>
                  </div>
                </component>
              </div>
            </div>

            <!-----------BOX RIGHT------------->
            <div class="w-full sm:w-6/12">
              <div class="border border-solid d-theme-border-grey-light border-r border-t border-b" style="height: 46vh">
                <div v-if="!boxLeft.selected" class="w-full h-full flex items-center">
                  <span class="w-full text-center text-sm opacity-75">Pilih item pada sebelah kiri.</span>
                </div>
                <component v-if="boxLeft.selected" :is="scrollbarTag" class="email-content-scroll-area" style="height: calc(100%)" :settings="scrollbarSettings" ref="mailListPS">
                  <div>
                    <div v-for="vendor in boxLeft.selected.vendors" :key="vendor.id" class="p-3 border border-solid d-theme-border-grey-light border-t-0 border-r-0 border-l-0 border-b">
                      <div class="flex items-center justify-between">
                        <div class="flex items-center">
                          <vs-checkbox color="success" v-model="vendor.checked" @input="onVendorChecked($event, vendor)"/>
                          <div>
                            <p class="text-sm font-bold" :class="{'italic': !vendor.nama_rekanan}">{{ vendor.nama_rekanan || 'Nama Rekanan' }}</p>
                            <p class="text-xs font-medium">Harga: {{ vendor.harga | idr }}</p>
                            <p class="text-xs opacity-75" v-if="vendor.keterangan">{{ vendor.keterangan }}</p>
                          </div>
                        </div>
                        <div class="flex items-center">
                          <a :href="vendor.file_penawaran_url" target="_blank" v-if="vendor.file_penawaran_url">
                            <vs-chip color="primary"><vs-avatar icon-pack="feather" icon="icon-file"/><span>File</span></vs-chip>
                          </a>
                          <feather-icon v-if="!vendor.id" @click="removeRow(vendor.uuid)" icon="XIcon" class="cursor-pointer text-danger mx-3" svg-classes="h-4 w-4"/>
                        </div>
                      </div>
                      <div v-if="vendor.showForm">
                        <vs-divider color="primary"></vs-divider>
                        <div class="w-full flex">
                          <div class="w-full sm:w-6/12 px-2">
                            <label class="ml-1 text-xs">Qty</label>
                            <vs-input class="w-full vs-input-no-shdow-focus" type="number" v-model="vendor.qty_actual"/>
                          </div>
                          <div class="w-full sm:w-6/12 px-2">
                            <label class="ml-1 text-xs mb-2">
                              Pilih Master Rekanan
                              <span v-if="!vendor.isDisableAddNewMaster"> / </span>
                              <span v-if="!vendor.isDisableAddNewMaster" class="text-primary underline cursor-pointer" @click="showModalRekananAdd(vendor)">Tambah Baru</span>
                            </label>
                            <vx-input-group>
                              <vs-input class="vs-input-no-shdow-focus" :value="vendor.kode_rekanan" readonly @click="showModalRekanan(vendor)"/>
                              <template slot="append">
                                <div class="append-text btn-addon">
                                  <vs-button type="filled" color="primary" icon-pack="feather" icon="icon-search" @click="showModalRekanan(vendor)"/>
                                </div>
                              </template>
                            </vx-input-group>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                </component>
              </div>
            </div>
          </div>
        </div>

        <div class="vx-row float-right mt-6">
          <div class="vx-col w-full">
            <vs-button class="mr-3" type="border" color="success" @click="emitModalIsActive(false)">Batal</vs-button>
            <vs-button :disabled="isLoading" color="success" @click="validate">
              <span v-if="isLoading" class="animate-pulse">Menyimpan...</span>
              <span v-if="!isLoading">Approve</span>
            </vs-button>
          </div>
        </div>
      </div>
    </vs-popup>

    <!--modals-->
    <PurchaseRequestApprovalAddRekanan :isActive.sync="modalRekananAdd.active"
                          :idProyek="initData.pengadaan ? initData.pengadaan.id_proyek : null"
                          :vendorName="modalRekananAdd.vendorName"
                          @success="onSuccessAddRekanan"/>

    <vs-popup class="sm:popup-w-70 popup-content-no-padding"
              title="Pilih Data Rekanan"
              :z-index="100001"
              :active="modalRekanan.active"
              v-on:update:active="modalRekanan.active = $event">
      <Rekanan :selectable="true"
               :externalFilter="filterRekanan"
               @selected="onSelectedModalRekanan"/>
    </vs-popup>
  </div>
</template>

<script>
import ValidationErrors from '@/views/components/validation-errors/ValidationErrors'
import PurchaseRequestApprovalRepository from '@/repositories/approvals/purchase-request-approval-repository'
import PengadaanRepository from '@/repositories/procurement/pengadaan-repository'
import Rekanan from '@/views/pages/master/rekanan/Rekanan'
import PurchaseRequestApprovalAddRekanan from '@/views/pages/approvals/purchase-request-approval/PurchaseRequestApprovalAddRekanan'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import moment from 'moment'
import { v4 as uuid } from 'uuid'
import _ from 'lodash'

export default {
  name: 'PurchaseRequestApprovalFinalApprove',
  props: ['isActive', 'idPengadaan'],
  components: {
    PurchaseRequestApprovalAddRekanan,
    Rekanan,
    ValidationErrors,
    VuePerfectScrollbar
  },
  computed: {
    filterRekanan () {
      return this.initData.pengadaan ? { id_proyek: this.initData.pengadaan.id_proyek } : null
    },
    scrollbarSettings () {
      return { maxScrollbarLength: 100, wheelSpeed: 0.80 }
    },
    scrollbarTag () {
      return this.$store.getters['theme/scrollbarTag']
    }
  },
  data () {
    return {
      isLoading: false,
      isLoadingGetInitData: false,
      errors: null,
      initData: {},
      data: {
        tgl: moment().format('YYYY-MM-DD'),
        keterangan: null
      },
      boxLeft: {
        selected: null
      },
      modalRekanan: {
        active: false,
        itemUuid: null,
        vendorUuid: null
      },
      modalRekananAdd: {
        active: false,
        itemUuid: null,
        vendorUuid: null,
        vendorName: null
      }
    }
  },
  watch: {
    idPengadaan (newVal, oldVal) {
      if (newVal !== oldVal) this.getInitData()
    },
    isActive (active) {
      if (active) {
        if (_.isEmpty(this.initData)) this.getInitData()
      }
    }
  },
  methods: {
    showModalRekanan (vendor) {
      this.modalRekanan.itemUuid = this.boxLeft.selected.uuid
      this.modalRekanan.vendorUuid = vendor.uuid
      this.modalRekanan.active = true
    },

    showModalRekananAdd (vendor) {
      this.modalRekananAdd.itemUuid = this.boxLeft.selected.uuid
      this.modalRekananAdd.vendorUuid = vendor.uuid
      this.modalRekananAdd.vendorName = vendor.nama_rekanan
      this.modalRekananAdd.active = true
    },

    onSelectedModalRekanan (item) {
      const indexItem = _.findIndex(this.initData.pengadaanD, item => item.uuid === this.modalRekanan.itemUuid)
      const indexVendor = _.findIndex(this.initData.pengadaanD[indexItem].vendors, item => item.uuid === this.modalRekanan.vendorUuid)
      const vendor = this.initData.pengadaanD[indexItem].vendors[indexVendor]
      vendor.id_rekanan = item.id
      vendor.kode_rekanan = item.kode
      vendor.nama_rekanan = item.nama
      this.modalRekanan.active = false
    },

    onSuccessAddRekanan (item) {
      const indexItem = _.findIndex(this.initData.pengadaanD, item => item.uuid === this.modalRekananAdd.itemUuid)
      const indexVendor = _.findIndex(this.initData.pengadaanD[indexItem].vendors, item => item.uuid === this.modalRekananAdd.vendorUuid)
      const vendor = this.initData.pengadaanD[indexItem].vendors[indexVendor]
      vendor.id_rekanan = item.id
      vendor.kode_rekanan = item.kode
      vendor.nama_rekanan = item.nama
      vendor.isDisableAddNewMaster = true
      this.modalRekananAdd.active = false
    },

    onVendorChecked (isChecked, vendor) {
      const item = this.boxLeft.selected
      const vendorChecked = _.filter(item.vendors, it => it.checked === true)
      if (vendorChecked.length === 1) vendor.qty_actual = item.qty
      vendor.showForm = !!isChecked
    },

    getInitData () {
      this.isLoadingGetInitData = true

      const idPengadaan = this.idPengadaan
      PengadaanRepository.show(idPengadaan)
        .then(response => {
          this.buildInitData(response.data.data)
        })
        .catch(error => {
          if (error.response.status === 404) {
            this.$router.push({ name: '404' })
          } else {
            console.log(error)
            this.notifyError('Terjadi kesalahan.')
          }
        })
        .finally(() => {
          this.isLoadingGetInitData = false
        })
    },

    buildInitData (initData) {
      const pengadaanDItems = initData.pengadaanD
      let pengadaanDItemsUnique = _.uniqBy(pengadaanDItems, item => item.id_item_pengadaan)
      pengadaanDItemsUnique = _.map(pengadaanDItemsUnique, item => {
        const it = item
        it.uuid = uuid()
        it.isAnyError = false
        it.vendors = _.cloneDeep(_.filter(pengadaanDItems, d => (d.is_has_vendors && d.id_item_pengadaan === item.id_item_pengadaan)))
        it.vendors = _.map(item.vendors, vendor => _.assign(vendor, { uuid: uuid(), checked: false, showForm: false, id_rekanan: null, qty_actual: null, isDisableAddNewMaster: false }))
        return it
      })
      this.initData = initData
      this.initData.pengadaanD = pengadaanDItemsUnique
    },

    addRow () {
      const index = _.findIndex(this.initData.pengadaanD, item => item.uuid === this.boxLeft.selected.uuid)
      const selected = this.boxLeft.selected
      const newRow = {
        uuid: uuid(),
        checked: false,
        showForm: false,
        isDisableAddNewMaster: false,
        id_pengadaan: selected.id_pengadaan,
        id_item_pengadaan: selected.id_item_pengadaan,
        id_rekanan: null,
        harga: selected.harga,
        qty: selected.qty,
        qty_actual: null
      }
      this.initData.pengadaanD[index].vendors.unshift(newRow)
      this.boxLeft.selected.vendors = this.initData.pengadaanD[index].vendors
    },

    removeRow (uuid) {
      const index = _.findIndex(this.boxLeft.selected.vendors, item => item.uuid === uuid)
      this.boxLeft.selected.vendors.splice(index, 1)
      const indexD = _.findIndex(this.initData.pengadaanD, item => item.uuid === this.boxLeft.selected.uuid)
      this.initData.pengadaanD[indexD].vendors = this.boxLeft.selected.vendors
    },

    validate () {
      this.errors = null
      const itemsWithError = _.filter(this.initData.pengadaanD, item => item.isAnyError === true)
      if (itemsWithError.length > 0) {
        this.errors = ['Pastikan semua data item sudah valid.']
      } else {
        this.save()
      }
    },

    save () {
      this.errors = null
      this.isLoading = true

      // build params
      const checkedVendors = _.flatten(_.map(this.initData.pengadaanD, item => {
        return _.filter(item.vendors, vendor => vendor.checked === true)
      }))
      const vendors = _.map(checkedVendors, item => _.pick(item, ['id', 'id_item_pengadaan', 'id_rekanan', 'harga', 'qty', 'qty_actual']))
      const params = { id_pengadaan: this.idPengadaan, keterangan: this.data.keterangan, vendors: vendors }

      PurchaseRequestApprovalRepository.finalApprove(params)
        .then(response => {
          this.onSuccess()
        })
        .catch(error => {
          console.log(error)
          if (error.response.status === 422) {
            this.errors = error.response.data.errors
          } else {
            this.notifyError('Terjadi kesalahan.')
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    itemCheckedVendors (item) {
      return _.filter(item.vendors, vendor => vendor.checked === true)
    },

    itemErrors (item) {
      const errors = []
      const checkedVendors = this.itemCheckedVendors(item)
      const totalQtyActual = _.sumBy(checkedVendors, vendor => parseFloat(vendor.qty_actual || 0))
      const notYetFillQty = _.find(checkedVendors, vendor => vendor.qty_actual === null)
      const notYetFillRekananMaster = _.find(checkedVendors, vendor => vendor.id_rekanan === null)
      if (checkedVendors.length === 0) errors.push('Wajib memilih rekanan setidaknya 1')
      if (totalQtyActual !== parseFloat(item.qty)) errors.push(`Total qty dipilih harus sama dengan ${item.qty}`)
      if (notYetFillQty) errors.push('Semua field qty wajib diisi')
      if (notYetFillRekananMaster) errors.push('Semua rekanan yang dipilih wajib ditambahkan ke data master')
      item.isAnyError = errors.length > 0
      return errors
    },

    itemErrorsText (item) {
      return this.itemErrors(item).join('; ')
    },

    onSuccess () {
      this.notifySuccess('Purchase request berhasil ditanggapi.')
      this.resetData()
      this.emitIsSuccess(true)
      this.emitModalIsActive(false)
      this.$store.dispatch('general/navbarBadge/getDataForMenuApproval')
    },

    resetData () {
      const exceptObjects = _.pick(this.$data, [])
      const newData = Object.assign(this.$options.data.call(this), exceptObjects)
      Object.assign(this.$data, newData)
    },

    emitIsSuccess (isSuccess) {
      this.$emit('success', isSuccess)
    },

    emitModalIsActive (isActive) {
      this.$emit('update:isActive', isActive)
    }
  }
}
</script>
