<template>
  <div>
    <vs-popup class="sm:popup-w-50" title="Tambah Rekanan" :z-index="100001" :active="isActive" v-on:update:active="emitModalIsActive">

      <div>
        <div class="vx-row">
          <div class="vx-col w-full">
            <ValidationErrors :errors="errors"/>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-6/12 w-full">
            <label class="ml-1 text-xs">Kode *</label>
            <vs-input class="w-full" v-model="data.kode" placeholder="Generate Otomatis" readonly/>
          </div>
          <div class="vx-col sm:w-6/12 w-full">
            <label class="ml-1 text-xs">Jenis Rekanan *</label>
            <vs-select autocomplete class="w-full" v-model="data.id_jenis_rekanan">
              <vs-select-item v-for="(it, index) in jenisRekanan" :key="index" :value="it.id" :text="it.nama"/>
            </vs-select>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-12/12 w-full">
            <label class="ml-1 text-xs">Nama *</label>
            <vs-input class="w-full" v-model="data.nama"/>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-6/12 w-full">
            <label class="ml-1 text-xs">Telp</label>
            <vs-input class="w-full" icon-pack="feather" icon="icon-phone" v-model="data.telp"/>
          </div>
          <div class="vx-col sm:w-6/12 w-full">
            <label class="ml-1 text-xs">Email</label>
            <vs-input class="w-full" icon-pack="feather" icon="icon-mail" v-model="data.email"/>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col w-full">
            <label class="ml-1 text-xs">Alamat</label>
            <vs-input class="w-full" icon-pack="feather" icon="icon-map-pin" v-model="data.alamat"/>
          </div>
        </div>

        <div class="vx-row float-right mt-6">
          <div class="vx-col w-full">
            <vs-button class="mr-3" type="border" @click="emitModalIsActive(false)">Batal</vs-button>
            <vs-button :disabled="isLoading" @click="save">
              <span v-if="isLoading" class="animate-pulse">Menyimpan...</span>
              <span v-if="!isLoading">Simpan</span>
            </vs-button>
          </div>
        </div>
      </div>

    </vs-popup>
  </div>
</template>

<script>
import RekananRepository from '@/repositories/master/rekanan-repository'
import JenisRekananRepository from '@/repositories/master/jenis-rekanan-repository'
import ValidationErrors from '@/views/components/validation-errors/ValidationErrors'
import _ from 'lodash'

export default {
  name: 'PurchaseRequestApprovalAddRekanan',
  props: ['isActive', 'idProyek', 'vendorName'],
  components: {
    ValidationErrors
  },
  mounted () {
    this.getAllJenisRekanan()
  },
  data () {
    return {
      isLoading: false,
      errors: null,
      data: {},
      jenisRekanan: []
    }
  },
  watch: {
    isActive (active) {
      if (active) {
        this.data.id_proyeks = [this.idProyek]
        this.data.nama = this.vendorName
      } else {
        this.resetData()
      }
    }
  },
  methods: {
    getAllJenisRekanan () {
      JenisRekananRepository.all()
        .then(response => {
          this.jenisRekanan = response.data.data
        })
        .catch(error => {
          console.log(error)
        })
    },

    save () {
      this.errors = null
      this.isLoading = true

      RekananRepository.create(this.data)
        .then(response => {
          this.onSuccess(response.data.data)
        })
        .catch(error => {
          console.log(error)
          if (error.response.status === 422) {
            this.errors = error.response.data.errors
          } else {
            this.notifyError('Terjadi kesalahan.')
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    onSuccess (data) {
      this.notifySuccess('Berhasil ditambah ke data master rekanan.')
      this.resetData()
      this.emitIsSuccess(true, data)
      this.emitModalIsActive(false)
    },

    resetData () {
      const exceptObjects = _.pick(this.$data, ['jenisRekanan'])
      const newData = Object.assign(this.$options.data.call(this), exceptObjects)
      Object.assign(this.$data, newData)
    },

    emitIsSuccess (isSuccess, data) {
      this.$emit('success', data)
    },

    emitModalIsActive (isActive) {
      this.$emit('update:isActive', isActive)
    }
  }
}
</script>
